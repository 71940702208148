<template>
  <div>
    <v-text-field
      v-model="searchPhrase"
      :loading="isLoading"
      label="Enter phrase"
      solo-inverted
      flat
      prepend-inner-icon="mdi-magnify"
      clearable
    />
  </div>
</template>

<script>
import { debounce } from "debounce";

export default {
  name: "SearchForm",
  props: {
    getProjects: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchPhrase: null
    };
  },
  watch: {
    searchPhrase(val) {
      if (val === null) {
        this.searchPhrase = "";
      }
      this.querySearchKnowledgeBase();
    }
  },
  methods: {
    querySearchKnowledgeBase: debounce(function() {
      this.getProjects({ query: this.searchPhrase, currentPage: 1 });
    }, 500)
  }
};
</script>
