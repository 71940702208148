<template>
  <v-container>
    <v-card class="pa-5">
      <v-card-title>
        <navigation-breadcrumbs />
      </v-card-title>
      <v-card-text>
        <div v-if="$route.name === 'KnowledgeBase'">
          <search-form
            ref="searchForm"
            :get-projects="handleGetProjects"
            :isLoading="isLoading"
          />
          <projects-list :projects="projects" :total="total" />

          <v-row dense>
            <v-spacer />
            <v-col cols="2">
              <v-select
                v-model="limitPerPage"
                :items="[4, 8, 12, 16]"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Per page"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-pagination
            v-if="projects.length > 0"
            v-model="page"
            :length="totalPages"
          />
        </div>
        <router-view v-else />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SearchForm from "@/views/KnowledgeBase/components/SearchForm";
import ProjectsList from "@/views/KnowledgeBase/components/ProjectsList";
import { mapActions } from "vuex";
import NavigationBreadcrumbs from "@/components/NavigationBreadcrumbs";
export default {
  name: "KnowledgeBase",
  components: { NavigationBreadcrumbs, ProjectsList, SearchForm },
  data() {
    return {
      isLoading: true,
      projects: [],
      total: 0,
      page: 1,
      limitPerPage: 12
    };
  },
  created() {
    this.handleGetProjects();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limitPerPage);
    }
  },
  watch: {
    page() {
      this.getProjectsWithSearchPhrase();
    },
    limitPerPage() {
      this.page = 1;
      this.getProjectsWithSearchPhrase();
    }
  },
  methods: {
    ...mapActions({
      getProjects: "api/knowledgeBase/getProjectsKnowledgeBase"
    }),
    getProjectsWithSearchPhrase() {
      let { searchPhrase } = this.$refs.searchForm;
      if (searchPhrase === null) {
        searchPhrase = "";
      }
      this.handleGetProjects({ query: searchPhrase });
    },
    handleGetProjects(query) {
      const defaultQuery = {
        limitPerPage: this.limitPerPage,
        currentPage: this.page,
        sortBy: { createdAt: "desc" }
      };
      this.isLoading = true;
      this.getProjects({
        ...defaultQuery,
        ...query
      })
        .then(response => {
          const { projects, total } = response.data;
          this.projects = projects;
          this.total = total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
