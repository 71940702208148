<template>
  <v-breadcrumbs class="pa-0" large :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "NavigationBreadcrumbs",
  props: {
    shiftMatched: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items() {
      const matched = [...this.$route.matched];

      if (this.shiftMatched) {
        matched.shift();
      }

      return matched.map(route => ({
        text: route.meta.humanName,
        disabled: this.$route.name === route.name,
        to: { name: route.name }
      }));
    }
  }
};
</script>
