import Highcharts from "highcharts";
import zipcelx from "zipcelx";
import slugify from "slugify";

export default function HighchartsConfig(H) {
  H.wrap(H.Chart.prototype, "setChartSize", function(proceed, skipAxes) {
    let chart = this,
      inverted = chart.inverted,
      renderer = chart.renderer,
      chartWidth = chart.chartWidth,
      chartHeight = chart.chartHeight,
      optionsChart = chart.options.chart,
      spacing = chart.spacing,
      clipOffset = chart.clipOffset,
      clipX,
      clipY,
      plotLeft,
      plotTop,
      plotWidth,
      plotHeight,
      plotBorderWidth,
      plotAreaWidth = chart.options.chart.plotAreaWidth,
      plotAreaHeight = chart.options.chart.plotAreaHeight;

    if (plotAreaWidth) {
      chart.plotWidth = plotWidth = plotAreaWidth;
      chart.plotLeft = plotLeft = Math.round((chartWidth - plotAreaWidth) / 2);
    } else {
      chart.plotLeft = plotLeft = Math.round(chart.plotLeft);
      chart.plotWidth = plotWidth = Math.max(
        0,
        Math.round(chartWidth - plotLeft - chart.marginRight)
      );
    }

    if (plotAreaHeight) {
      chart.plotTop = plotTop = Math.round((chartHeight - plotAreaHeight) / 2);
      chart.plotHeight = plotHeight = plotAreaHeight;
    } else {
      chart.plotTop = plotTop = Math.round(chart.plotTop);
      chart.plotHeight = plotHeight = Math.max(
        0,
        Math.round(chartHeight - plotTop - chart.marginBottom)
      );
    }

    chart.plotSizeX = inverted ? plotHeight : plotWidth;
    chart.plotSizeY = inverted ? plotWidth : plotHeight;

    chart.plotBorderWidth = optionsChart.plotBorderWidth || 0;

    // Set boxes used for alignment
    chart.spacingBox = renderer.spacingBox = {
      x: spacing[3],
      y: spacing[0],
      width: chartWidth - spacing[3] - spacing[1],
      height: chartHeight - spacing[0] - spacing[2]
    };
    chart.plotBox = renderer.plotBox = {
      x: plotLeft,
      y: plotTop,
      width: plotWidth,
      height: plotHeight
    };

    plotBorderWidth = 2 * Math.floor(chart.plotBorderWidth / 2);
    clipX = Math.ceil(Math.max(plotBorderWidth, clipOffset[3]) / 2);
    clipY = Math.ceil(Math.max(plotBorderWidth, clipOffset[0]) / 2);
    chart.clipBox = {
      x: clipX,
      y: clipY,
      width: Math.floor(
        chart.plotSizeX - Math.max(plotBorderWidth, clipOffset[1]) / 2 - clipX
      ),
      height: Math.max(
        0,
        Math.floor(
          chart.plotSizeY - Math.max(plotBorderWidth, clipOffset[2]) / 2 - clipY
        )
      )
    };

    if (!skipAxes) {
      Highcharts.each(chart.axes, function(axis) {
        axis.setAxisSize();
        axis.setAxisTranslation();
      });
    }
  });

  if (zipcelx && H.getOptions().exporting) {
    H.Chart.prototype.downloadXLSX = function() {
      let div = document.createElement("div"),
        name,
        xlsxRows = [],
        rows;
      div.style.display = "none";
      document.body.appendChild(div);
      rows = this.getDataRows(true);
      xlsxRows = H.map(rows.slice(1), function(row) {
        return H.map(row, function(column) {
          return {
            type: typeof column === "number" ? "number" : "string",
            value: column
          };
        });
      });

      // Get the filename, copied from the Chart.fileDownload function
      let titleStr = this?.title?.textStr;
      let type = this?.userOptions?.chart?.type;
      let fileName = this?.options?.exporting?.filename;
      if (fileName) {
        name = fileName;
      } else if (titleStr && type) {
        switch (type) {
          case "bubble":
            name = `grid ${titleStr}`;
            break;
          case "solidgauge":
            name = `pillars ${titleStr}`;
            break;
          case "bar":
            name = `${type} ${titleStr}`;
            break;
          default:
            name = titleStr;
        }
        name = slugify(name, { lower: true });
      }

      zipcelx({
        filename: name,
        sheet: {
          data: xlsxRows
        }
      });
    };

    // Default lang string, overridable in i18n options
    H.getOptions().lang.downloadXLSX = "Download XLSX";

    // Add the menu item handler
    H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
      textKey: "downloadXLSX",
      onclick: function() {
        this.downloadXLSX();
      }
    };

    // Replace the menu item
    let menuItems = H.getOptions().exporting.buttons.contextButton.menuItems;
    menuItems[menuItems.indexOf("downloadXLS")] = "downloadXLSX";
  }
}
