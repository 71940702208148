<template>
  <div>
    <v-row>
      <v-col v-for="project in projects" :key="project._id" cols="3">
        <project-card :project="project" knowledge-base />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProjectCard from "@/views/Projects/components/ProjectCard";
export default {
  name: "ProjectsList",
  components: { ProjectCard },
  props: {
    projects: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  }
};
</script>
